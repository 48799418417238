<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('ChatbotAnswersCreate')"
    @edit="(id) => onEdit('ChatbotAnswersEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'ChatbotAnswers',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'ChatbotAnswers',
      tableCaption: 'Ответы на вопросы для чат-бота',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Ответы на вопросы для чат-бота',
        },
      ],
      tableHeaders: [
        { text: 'ID', alias: 'id', order: '' },
        {
          text: 'Текст ответа',
          alias: 'answerText',
          order: '',
        },
        { text: 'База данных', alias: 'baseName', order: '' },
        { text: '', alias: 'actions', order: '' },
      ],
    };
  },
};
</script>
